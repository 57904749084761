ngb-datepicker {
  border: none !important;

  ngb-datepicker-navigation {
    margin-bottom: 12px;
  }

  $cell-size-l: 2.75rem;
  $cell-size-m: 2.5rem;
  $cell-size-s: 2.25rem;

  .ngb-dp-day,
  .ngb-dp-day > div,
  .ngb-dp-weekday {
    width: $cell-size-l !important;
    height: $cell-size-l !important;
    line-height: $cell-size-l !important;

    @media (max-width: 425px) {
      width: $cell-size-m !important;
      height: $cell-size-m !important;
      line-height: $cell-size-m !important;
    }
    @media (max-width: 375px) {
      width: $cell-size-s !important;
      height: $cell-size-s !important;
      line-height: $cell-size-s !important;
    }
  }

  .ngb-dp-months {
    flex-direction: column;
  }

  .ngb-dp-week {
    display: flex;
    justify-content: space-between;
  }

  .ngb-dp-weekdays {
    border: none !important;
  }

  .ngb-dp-weekday {
    font-style: normal !important;
  }
}

@import '../maps/wilson-colors.scss';

.ellipsis {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  max-width: 100% !important;
}

.text-headline-1 {
  font-weight: bold !important;
  font-size: 24px !important;
  line-height: 32px !important;
}

.text-headline-2 {
  font-size: 20px !important;
  line-height: 27px !important;
}

.text-headline-3 {
  font-size: 18px !important;
  line-height: 24px !important;
}

.text-body-1 {
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 21px !important;
}

.text-body-2 {
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 19px !important;
}

.text-subtitle {
  font-size: 14px !important;
  line-height: 19px !important;
}

.text-overline {
  font-size: 10px !important;
  line-height: 13px !important;
}

.text-button {
  font-size: 16px !important;
  line-height: 24px !important;
}

.text-button-small {
  font-size: 14px !important;
  line-height: 18px !important;
}

.text-link {
  font-weight: normal !important;
  font-size: 14px !important;
}

.text-input {
  font-weight: normal !important;
  font-size: 18px !important;
  line-height: 24px !important;
}

.text-label {
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 21px !important;
}

.text-caption {
  font-size: 12px !important;
  line-height: 16px !important;
}

// ! Legacy types, should not be used in the future

.bold {
  font-weight: bold !important;
}

.bolder {
  font-weight: bolder;
}

.label {
  color: var(--grey-6);
  font-size: 14px;
  line-height: 16px;
}

.headline-1 {
  font-size: 24px !important;
  font-weight: bold !important;
}

.headline-2 {
  font-size: 20px !important;
  font-weight: bold !important;
}

.page-headline {
  @extend .headline-1;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100% !important;
}

// text colors
.gray {
  color: var(--grey-5) !important;
}
.light-gray {
  color: var(--grey-5-light) !important;
}
.dark-gray {
  color: var(--grey-6) !important;
}
.dark {
  color: var(--grey-10) !important;
}
.dark-medium {
  color: var(--grey-9) !important;
}
.dark-light {
  color: var(--grey-8) !important;
}
.primary {
  color: var(--ion-color-primary) !important;
}
.primary-light {
  color: var(--ion-color-primary-tint) !important;
}
.primary-dark {
  color: var(--ion-color-primary-shade) !important;
}
.red {
  color: var(--ion-color-danger) !important;
}

.text-sm-medium {
  font-size: 12px;
  color: #afb4bb;
}

.text-sm-secondary {
  font-size: 12px;
  color: #575757;
}

.text-sm {
  font-size: 12px;
  color: #2e2e2e;
}

.text-normal-primary {
  font-size: 14px;
  color: #84ffcc;
}

.text-normal-medium {
  font-size: 14px;
  color: #afb4bb;
}

.text-normal-secondary {
  font-size: 14px;
  color: #575757;
}

.text-normal {
  font-size: 14px;
  color: #2e2e2e;
}

.text-lg-primary {
  font-size: 18px;
  color: #84ffcc;
}

.text-lg-danger {
  font-size: 18px;
  color: var(--ion-color-danger);
}

.text-lg-medium {
  font-size: 18px;
  color: #afb4bb;
}

.text-lg-secondary {
  font-size: 18px;
  color: #575757;
}

.text-lg {
  // = text-order-1
  font-size: 18px;
  color: #2e2e2e;
}

.header {
  font-family: var(--header-font-family);
  font-size: var(--header-font-size);
  letter-spacing: var(--header-letter-spacing);
  line-height: var(--header-line-height);
  font-weight: var(--header-font-weight);
}

.header-bold {
  font-family: var(--header-bold-font-family);
  font-size: var(--header-bold-font-size);
  letter-spacing: var(--header-bold-letter-spacing);
  line-height: var(--header-bold-line-height);
  font-weight: var(--header-bold-font-weight);
}

.header-2 {
  font-family: var(--header-2-font-family);
  font-size: var(--header-2-font-size);
  letter-spacing: var(--header-2-letter-spacing);
  line-height: var(--header-2-line-height);
  font-weight: var(--header-2-font-weight);
}

.header-2-bold {
  font-family: var(--header-2-bold-font-family);
  font-size: var(--header-2-bold-font-size);
  letter-spacing: var(--header-2-bold-letter-spacing);
  line-height: var(--header-2-bold-line-height);
  font-weight: var(--header-2-bold-font-weight);
}

.text-order-1 {
  font-family: var(--text-order-1-font-family);
  font-size: var(--text-order-1-font-size);
  letter-spacing: var(--text-order-1-letter-spacing);
  line-height: var(--text-order-1-line-height);
  font-weight: var(--text-order-1-font-weight);
}

.text-order-1-bold {
  font-family: var(--text-order-1-bold-font-family);
  font-size: var(--text-order-1-bold-font-size);
  letter-spacing: var(--text-order-1-bold-letter-spacing);
  line-height: var(--text-order-1-bold-line-height);
  font-weight: var(--text-order-1-bold-font-weight);
}

.text-order-2 {
  font-family: var(--text-order-2-font-family);
  font-size: var(--text-order-2-font-size);
  letter-spacing: var(--text-order-2-letter-spacing);
  line-height: var(--text-order-2-line-height);
  font-weight: var(--text-order-2-font-weight);
}

.text-order-2-bold {
  font-family: var(--text-order-2-bold-font-family);
  font-size: var(--text-order-2-bold-font-size);
  letter-spacing: var(--text-order-2-bold-letter-spacing);
  line-height: var(--text-order-2-bold-line-height);
  font-weight: var(--text-order-2-bold-font-weight);
}

.text-order-3 {
  font-family: var(--text-order-3-font-family);
  font-size: var(--text-order-3-font-size);
  letter-spacing: var(--text-order-3-letter-spacing);
  line-height: var(--text-order-3-line-height);
  font-weight: var(--text-order-3-font-weight);
}

.text-order-3-bold {
  font-family: var(--text-order-3-bold-font-family);
  font-size: var(--text-order-3-bold-font-size);
  letter-spacing: var(--text-order-3-bold-letter-spacing);
  line-height: var(--text-order-3-bold-line-height);
  font-weight: var(--text-order-3-bold-font-weight);
}

.text-order-4 {
  font-family: var(--text-order-4-font-family);
  font-size: var(--text-order-4-font-size);
  letter-spacing: var(--text-order-4-letter-spacing);
  line-height: var(--text-order-4-line-height);
  font-weight: var(--text-order-4-font-weight);
}

.text-order-4-bold {
  font-family: var(--text-order-4-bold-font-family);
  font-size: var(--text-order-4-bold-font-size);
  letter-spacing: var(--text-order-4-bold-letter-spacing);
  line-height: var(--text-order-4-bold-line-height);
  font-weight: var(--text-order-4-bold-font-weight);
}

:root {
  // ! Legacy types, should not be used in the future

  --header-font-size: 24px;
  --header-letter-spacing: 0px;
  --header-line-height: 28px;

  --header-bold-font-size: 24px;
  --header-bold-letter-spacing: 0px;
  --header-bold-line-height: 28px;
  --header-bold-font-weight: 700;

  --header-2-font-size: 24px;
  --header-2-letter-spacing: 0px;
  --header-2-line-height: 26px;

  --header-2-bold-font-size: 24px;
  --header-2-bold-letter-spacing: 0px;
  --header-2-bold-line-height: 26px;
  --header-2-bold-font-weight: 700;

  /** text-order-1 **/
  --text-order-1-font-size: 18px;
  --text-order-1-letter-spacing: 0px;
  --text-order-1-line-height: 22px;
  --text-order-1-font-weight: 400;
  /** text-order-1-bold **/
  --text-order-1-bold-font-size: 18px;
  --text-order-1-bold-letter-spacing: 0px;
  --text-order-1-bold-line-height: 22px;
  --text-order-1-bold-font-weight: 700;
  --text-order-1-bold-color: black;
  /** text-order-2 **/
  --text-order-2-font-size: 15px;
  --text-order-2-letter-spacing: 0px;
  --text-order-2-line-height: 20px;
  --text-order-2-font-weight: 400;
  --text-order-2-color: black;
  /** text-order-2-bold **/
  --text-order-2-bold-font-size: 15px;
  --text-order-2-bold-letter-spacing: 0px;
  --text-order-2-bold-line-height: 20px;
  --text-order-2-bold-font-weight: 700;
  --text-order-2-bold-color: black;
  /** text-order-3 **/
  --text-order-3-font-size: 13px;
  --text-order-3-letter-spacing: 0px;
  --text-order-3-line-height: 16px;
  --text-order-3-font-weight: 400;
  --text-order-3-color: black;
  /** text-order-3-bold **/
  --text-order-3-bold-font-size: 13px;
  --text-order-3-bold-letter-spacing: 0px;
  --text-order-3-bold-line-height: 16px;
  --text-order-3-bold-font-weight: 700;
  --text-order-3-bold-color: black;
  /** text-order-4 **/
  --text-order-4-font-size: 11px;
  --text-order-4-letter-spacing: 0px;
  --text-order-4-line-height: 14px;
  --text-order-4-font-weight: 400;
  /** text-order-4-bold **/
  --text-order-4-bold-font-size: 11px;
  --text-order-4-bold-letter-spacing: 0px;
  --text-order-4-bold-line-height: 14px;
  --text-order-4-bold-font-weight: 700;
}

ion-skeleton-text {
  &.skeleton-text-small {
    width: 30%;
    --border-radius: 6px;
  }
  &.skeleton-text-medium {
    width: 60%;
    --border-radius: 6px;
  }
  &.skeleton-text-large {
    width: 90%;
    --border-radius: 6px;
  }
}

// display
.d-none {
  display: none;
}

.d-block {
  display: block;
}

// flexbox
.align-center {
  display: flex;
  align-items: center;
}

.justify-end {
  display: flex;
  justify-content: flex-end;
}

.no-wrap {
  display: flex;
  flex-wrap: nowrap;
}

// containers and wrappers
.empty-state {
  padding-top: calc(10vh + 30px);
  max-height: 100%;
  box-sizing: border-box;
  text-align: center;
  ion-img {
    height: 78px;
  }
}

.content-wrapper {
  height: 100%;
  min-height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 24px;
}

.spinner-wrapper {
  padding: 40px;
  width: 100%;
  display: flex;
  justify-content: center;

  &-full {
    @extend .spinner-wrapper;
    top: 30%;
    left: 0;
    right: 0;
    position: absolute;
  }
}

.card {
  margin: 12px 0;
  padding: 10px;
  background-color: var(--grey-1);
  border: 1px solid var(--grey-3);
  border-radius: 8px;
  box-sizing: border-box;

  &.card-flat {
    box-shadow: none;
    background-color: transparent;
    border-color: var(--grey-9);
  }
}

.modal-class-popup-info-calendar .modal-wrapper {
  width: 86%;
  max-height: 25rem;
  border-radius: 5px;
}

// Temp fix for modal backdropdismiss until the issue is patched in ionic6
.ionic-backdrop-dismiss-fix {
  --height: auto;
}

.modal-variable-height {
  .ion-page {
    position: relative;
    contain: content;
  }
  &::part(content) {
    --width: 86%;
    --height: auto;
    padding: 0;
    border-radius: 5px;
  }
}

.alert-modal {
  @extend .fit-content-height-modal;
  --width: 70% !important;
  --border-radius: 10px !important;
  @media (max-width: 375px) {
    --width: 75% !important;
  }
  @media (max-width: 320px) {
    --width: 85% !important;
  }
  @media (max-width: 280px) {
    --width: 95% !important;
  }
  &-large {
    @extend .alert-modal;
    --width: 80% !important;
    @media (max-width: 375px) {
      --width: 85% !important;
    }
    @media (max-width: 320px) {
      --width: 95% !important;
    }
    @media (max-width: 280px) {
      --width: 97.5% !important;
    }
  }
}

.fit-content-height-modal {
  --height: auto !important;
  .ion-page {
    position: relative;
    contain: content;
  }
  &::part(content) {
    padding: 1rem;
  }
}

.modal-wrapper {
  height: 100%;
  padding: 24px 24px 50px;
}

.modal-stacked-backdrop-fix {
  --backdrop-opacity: 50%;
}

.fit-content-sheet-modal {
  --height: auto;
  .ion-page {
    position: relative;
    contain: content;
  }
}

ion-modal.stack-modal {
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

ion-action-sheet .action-sheet-button.action-sheet-cancel {
  color: var(--grey-9);
}

.modal-container {
  gap: 1.5rem;
  display: flex;
  flex-flow: column;
  align-items: center;
  background-color: var(--ion-color-light);
  padding: 1rem;
  height: 100%;

  .modal-text {
    gap: 0.5rem;
    display: flex;
    flex-flow: column;
    align-items: center;
    text-align: center;

    .modal-title {
      @extend .text-headline-2;

      color: var(--grey-10);
    }

    .modal-message {
      @extend .text-body-1;

      white-space: pre-line;
      flex-grow: 1;
      overflow-y: scroll;
      text-align: center;

      color: var(--grey-8);
    }
  }

  .modal-buttons {
    width: 100%;
    gap: 0.5rem;
    display: flex;
    flex-flow: column;

    .button-container {
      width: 100%;
      gap: 0.25rem;
      display: flex;
      flex-flow: column;

      .button-label {
        @extend .text-caption;
        text-align: center;
        color: var(--grey-8);
      }

      ion-button {
        margin: 0;
      }
    }
  }
}

a {
  color: var(--geekblue-6);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

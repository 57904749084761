@import '../maps/wilson-colors';

// Wilson Color
$wilson: #84ffcc;

// Base colors from Ant Design
$red-1: #fff1f0;
$red-2: #ffccc7;
$red-3: #ffa39e;
$red-4: #ff7875;
$red-5: #ff4d4f;
$red-6: #f5222d;
$red-7: #cf1322;
$red-8: #a8071a;
$red-9: #820014;
$red-10: #5c0011;

$volcano-1: #fff2e8;
$volcano-2: #ffd8cc;
$volcano-3: #ffbb96;
$volcano-4: #ff9c6e;
$volcano-5: #ff7a45;
$volcano-6: #fa541c;
$volcano-7: #d4380d;
$volcano-8: #ad2102;
$volcano-9: #871400;
$volcano-10: #610b00;

$orange-1: #fff7e6;
$orange-2: #ffe7ba;
$orange-3: #ffd591;
$orange-4: #ffc069;
$orange-5: #ffa940;
$orange-6: #fa8c16;
$orange-7: #d46b08;
$orange-8: #ad4e00;
$orange-9: #873800;
$orange-10: #612500;

$gold-1: #fffbe6;
$gold-2: #fff1b8;
$gold-3: #ffe58f;
$gold-4: #ffd666;
$gold-5: #ffc53d;
$gold-6: #faad14;
$gold-7: #d48806;
$gold-8: #ad6800;
$gold-9: #874d00;
$gold-10: #613400;

$yellow-1: #feffe6;
$yellow-2: #ffffb8;
$yellow-3: #fffb8f;
$yellow-4: #fff566;
$yellow-5: #ffec3d;
$yellow-6: #fadb14;
$yellow-7: #d4b106;
$yellow-8: #ad8b00;
$yellow-9: #876800;
$yellow-10: #614700;

$lime-1: #fcffe6;
$lime-2: #f4ffb8;
$lime-3: #eaff8f;
$lime-4: #d3f261;
$lime-5: #bae637;
$lime-6: #a0d911;
$lime-7: #7cb305;
$lime-8: #5b8c00;
$lime-9: #3f6600;
$lime-10: #254000;

$green-1: #f6ffed;
$green-2: #d9f7be;
$green-3: #b7eb8f;
$green-4: #95de64;
$green-5: #73d13d;
$green-6: #52c41a;
$green-7: #389e0d;
$green-8: #237804;
$green-9: #135200;
$green-10: #092b00;

$cyan-1: #e6fffb;
$cyan-2: #b5f5ec;
$cyan-3: #87e8de;
$cyan-4: #5cdbd3;
$cyan-5: #36cfc9;
$cyan-6: #13c2c2;
$cyan-7: #08979c;
$cyan-8: #006d75;
$cyan-9: #00474f;
$cyan-10: #002329;

$blue-1: #e6f7ff;
$blue-2: #bae7ff;
$blue-3: #91d5ff;
$blue-4: #69c0ff;
$blue-5: #40a9ff;
$blue-6: #1890ff;
$blue-7: #096dd9;
$blue-8: #0050b3;
$blue-9: #003a8c;
$blue-10: #002766;

$geekblue-1: #f0f5ff;
$geekblue-2: #d6e4ff;
$geekblue-3: #adc6ff;
$geekblue-4: #85a5ff;
$geekblue-5: #597ef7;
$geekblue-6: #2f54eb;
$geekblue-7: #1d39c4;
$geekblue-8: #10239e;
$geekblue-9: #061178;
$geekblue-10: #030852;

$purple-1: #f9f0ff;
$purple-2: #efdbff;
$purple-3: #d3adf7;
$purple-4: #b37feb;
$purple-5: #9254de;
$purple-6: #722ed1;
$purple-7: #531dab;
$purple-8: #391085;
$purple-9: #22075e;
$purple-10: #120338;

$magenta-1: #fff0f6;
$magenta-2: #ffd6e7;
$magenta-3: #ffadd2;
$magenta-4: #ff85c0;
$magenta-5: #f759ab;
$magenta-6: #eb2f96;
$magenta-7: #c41d7f;
$magenta-8: #9e1068;
$magenta-9: #780650;
$magenta-10: #520339;

// Neutral colors from Ant Design
$grey-1: #ffffff;
$grey-2: #fafafa;
$grey-3: #f5f5f5;
$grey-4: #f0f0f0;
$grey-5: #d9d9d9;
$grey-6: #bfbfbf;
$grey-7: #8c8c8c;
$grey-8: #595959;
$grey-9: #262626;
$grey-10: #141414;
$grey-11: #000000;

:root {
  --ion-color-primary: #60cc9f;
  --ion-color-primary-rgb: 96, 204, 159;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #1f9966;
  --ion-color-primary-tint: #87e0bb;

  --ion-color-secondary: #722ed1;
  --ion-color-secondary-rgb: 114, 46, 209;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #6428b8;
  --ion-color-secondary-tint: #8043d6;

  --ion-color-tertiary: #c41d7f;
  --ion-color-tertiary-rgb: 196, 29, 127;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #ac1a70;
  --ion-color-tertiary-tint: #ca348c;

  --ion-color-success: #2dd55b;
  --ion-color-success-rgb: 45, 213, 91;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #28bb50;
  --ion-color-success-tint: #42d96b;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #c5000f;
  --ion-color-danger-rgb: 197, 0, 15;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #ad000d;
  --ion-color-danger-tint: #cb1a27;

  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 255, 255, 255;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d8dade;
  --ion-color-light-tint: #f7f9fc;

  --ion-color-medium: #5f5f5f;
  --ion-color-medium-rgb: 95, 95, 95;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #545454;
  --ion-color-medium-tint: #6f6f6f;

  --ion-color-dark: #2f2f2f;
  --ion-color-dark-rgb: 47, 47, 47;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #292929;
  --ion-color-dark-tint: #444444;

  // Wilson Color
  --wilson: #84ffcc;

  //Base Color Palette from Ant Design
  --red-1: #{$red-1};
  --red-2: #{$red-2};
  --red-3: #{$red-3};
  --red-4: #{$red-4};
  --red-5: #{$red-5};
  --red-6: #{$red-6};
  --red-7: #{$red-7};
  --red-8: #{$red-8};
  --red-9: #{$red-9};
  --red-10: #{$red-10};

  --volcano-1: #{$volcano-1};
  --volcano-2: #{$volcano-2};
  --volcano-3: #{$volcano-3};
  --volcano-4: #{$volcano-4};
  --volcano-5: #{$volcano-5};
  --volcano-6: #{$volcano-6};
  --volcano-7: #{$volcano-7};
  --volcano-8: #{$volcano-8};
  --volcano-9: #{$volcano-9};
  --volcano-10: #{$volcano-10};

  --orange-1: #{$orange-1};
  --orange-2: #{$orange-2};
  --orange-3: #{$orange-3};
  --orange-4: #{$orange-4};
  --orange-5: #{$orange-5};
  --orange-6: #{$orange-6};
  --orange-7: #{$orange-7};
  --orange-8: #{$orange-8};
  --orange-9: #{$orange-9};
  --orange-10: #{$orange-10};

  --gold-1: #{$gold-1};
  --gold-2: #{$gold-2};
  --gold-3: #{$gold-3};
  --gold-4: #{$gold-4};
  --gold-5: #{$gold-5};
  --gold-6: #{$gold-6};
  --gold-7: #{$gold-7};
  --gold-8: #{$gold-8};
  --gold-9: #{$gold-9};
  --gold-10: #{$gold-10};

  --yellow-1: #{$yellow-1};
  --yellow-2: #{$yellow-2};
  --yellow-3: #{$yellow-3};
  --yellow-4: #{$yellow-4};
  --yellow-5: #{$yellow-5};
  --yellow-6: #{$yellow-6};
  --yellow-7: #{$yellow-7};
  --yellow-8: #{$yellow-8};
  --yellow-9: #{$yellow-9};
  --yellow-10: #{$yellow-10};

  --lime-1: #{$lime-1};
  --lime-2: #{$lime-2};
  --lime-3: #{$lime-3};
  --lime-4: #{$lime-4};
  --lime-5: #{$lime-5};
  --lime-6: #{$lime-6};
  --lime-7: #{$lime-7};
  --lime-8: #{$lime-8};
  --lime-9: #{$lime-9};
  --lime-10: #{$lime-10};

  --green-1: #{$green-1};
  --green-2: #{$green-2};
  --green-3: #{$green-3};
  --green-4: #{$green-4};
  --green-5: #{$green-5};
  --green-6: #{$green-6};
  --green-7: #{$green-7};
  --green-8: #{$green-8};
  --green-9: #{$green-9};
  --green-10: #{$green-10};

  --cyan-1: #{$cyan-1};
  --cyan-2: #{$cyan-2};
  --cyan-3: #{$cyan-3};
  --cyan-4: #{$cyan-4};
  --cyan-5: #{$cyan-5};
  --cyan-6: #{$cyan-6};
  --cyan-7: #{$cyan-7};
  --cyan-8: #{$cyan-8};
  --cyan-9: #{$cyan-9};
  --cyan-10: #{$cyan-10};

  --blue-1: #{$blue-1};
  --blue-2: #{$blue-2};
  --blue-3: #{$blue-3};
  --blue-4: #{$blue-4};
  --blue-5: #{$blue-5};
  --blue-6: #{$blue-6};
  --blue-7: #{$blue-7};
  --blue-8: #{$blue-8};
  --blue-9: #{$blue-9};
  --blue-10: #{$blue-10};

  --geekblue-1: #{$geekblue-1};
  --geekblue-2: #{$geekblue-2};
  --geekblue-3: #{$geekblue-3};
  --geekblue-4: #{$geekblue-4};
  --geekblue-5: #{$geekblue-5};
  --geekblue-6: #{$geekblue-6};
  --geekblue-7: #{$geekblue-7};
  --geekblue-8: #{$geekblue-8};
  --geekblue-9: #{$geekblue-9};
  --geekblue-10: #{$geekblue-10};

  --purple-1: #{$purple-1};
  --purple-2: #{$purple-2};
  --purple-3: #{$purple-3};
  --purple-4: #{$purple-4};
  --purple-5: #{$purple-5};
  --purple-6: #{$purple-6};
  --purple-7: #{$purple-7};
  --purple-8: #{$purple-8};
  --purple-9: #{$purple-9};
  --purple-10: #{$purple-10};

  --magenta-1: #{$magenta-1};
  --magenta-2: #{$magenta-2};
  --magenta-3: #{$magenta-3};
  --magenta-4: #{$magenta-4};
  --magenta-5: #{$magenta-5};
  --magenta-6: #{$magenta-6};
  --magenta-7: #{$magenta-7};
  --magenta-8: #{$magenta-8};
  --magenta-9: #{$magenta-9};
  --magenta-10: #{$magenta-10};

  //Neutral color Palette from Ant Design
  --grey-1: #{$grey-1};
  --grey-2: #{$grey-2};
  --grey-3: #{$grey-3};
  --grey-4: #{$grey-4};
  --grey-5: #{$grey-5};
  --grey-6: #{$grey-6};
  --grey-7: #{$grey-7};
  --grey-8: #{$grey-8};
  --grey-9: #{$grey-9};
  --grey-10: #{$grey-10};
  --grey-11: #{$grey-11};

  //Application Colors
  //Item
  --ion-item-background: #{$grey-2};
  --ion-item-color: #{$grey-10};
  --ion-item-color: #{$grey-10};
  //Card
  --ion-card-background: #{$grey-1};
  //Background
  --ion-background-color: #{$grey-2};
  --ion-background-color-rgb: 250, 250, 250;
  //Tab
  --ion-tab-bar-background: none;
  --ion-tab-bar-background-focused: none;
  --ion-tab-bar-border-color: #d9d9d9;
  --ion-tab-bar-color: #8c8c8c;
  --ion-tab-bar-color-selected: var(--ion-color-primary-shade);
  //Toolbar
  --ion-toolbar-border-color: #d9d9d9;
  --ion-toolbar-color: #{$grey-10};
  --ion-toolbar-segment-color: #8c8c8c;
  --ion-toolbar-segment-color-checked: #{$grey-10};
  --ion-toolbar-segment-background: none;
  --ion-toolbar-segment-background-checked: none;
  --ion-toolbar-background: #{$grey-2};
  //Text
  --ion-text-color: #000000;
  --ion-text-color-rgb: 0, 0, 0;
}

// Darkmode
@media (prefers-color-scheme: dark) {
  :root {
    --ion-color-primary: #84ffcc;
    --ion-color-primary-rgb: 132, 255, 204;
    --ion-color-primary-contrast: #000000;
    --ion-color-primary-contrast-rgb: 0, 0, 0;
    --ion-color-primary-shade: #74e0b4;
    --ion-color-primary-tint: #90ffd1;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80, 200, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106, 100, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47, 223, 117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255, 213, 52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255, 73, 97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }
}

/* Fallback for older browsers or manual mode */
body.dark {
  --ion-color-primary: #84ffcc;
  --ion-color-primary-rgb: 132, 255, 204;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #74e0b4;
  --ion-color-primary-tint: #90ffd1;

  --ion-color-secondary: #50c8ff;
  --ion-color-secondary-rgb: 80, 200, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #46b0e0;
  --ion-color-secondary-tint: #62ceff;

  --ion-color-tertiary: #6a64ff;
  --ion-color-tertiary-rgb: 106, 100, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #5d58e0;
  --ion-color-tertiary-tint: #7974ff;

  --ion-color-success: #2fdf75;
  --ion-color-success-rgb: 47, 223, 117;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;

  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: 255, 213, 52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;

  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: 255, 73, 97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #222428;
  --ion-color-light-rgb: 34, 36, 40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;

  --grey-1: #{$grey-11};
  --grey-2: #{$grey-10};
  --grey-3: #{$grey-9};
  --grey-4: #{$grey-8};
  --grey-5: #{$grey-7};
  --grey-6: #{$grey-6};
  --grey-7: #{$grey-5};
  --grey-8: #{$grey-4};
  --grey-9: #{$grey-3};
  --grey-10: #{$grey-2};
  --grey-11: #{$grey-1};

  //Application Colors
  //Item
  --ion-item-background: transparent;
  --ion-item-border-color: #{$grey-8};
  --ion-item-color: #{$grey-1};
  //Card
  --ion-card-background: #{$grey-9};
  //Background
  --ion-background-color: #{$grey-10};
  --ion-background-color-rgb: 20, 20, 20;
  //Tabbar
  --ion-tab-bar-background: #{$grey-11};
  --ion-tab-bar-background-focused: #{$grey-10};
  --ion-tab-bar-border-color: #{$grey-4};
  --ion-tab-bar-color: #{$grey-3};
  --ion-tab-bar-color-selected: var(--ion-color-primary);
  //Toolbar
  --ion-toolbar-border-color: #{$grey-4};
  --ion-toolbar-color: #{$grey-2};
  --ion-toolbar-segment-color: #{$grey-3};
  --ion-toolbar-segment-color-checked: #{$grey-2};
  --ion-toolbar-segment-background: none;
  --ion-toolbar-segment-background-checked: none;
  --ion-toolbar-background: #{$grey-10};
  //Text
  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;
  //Steps
  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #191919;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;
  //Colors
  --geekblue-8: #{$geekblue-3};
  --geekblue-6: #{$geekblue-4};
}
body:not(.dark) {
  --ion-color-primary: #60cc9f;
  --ion-color-primary-rgb: 96, 204, 159;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #1f9966;
  --ion-color-primary-tint: #87e0bb;

  --ion-color-secondary: #722ed1;
  --ion-color-secondary-rgb: 114, 46, 209;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #6428b8;
  --ion-color-secondary-tint: #8043d6;

  --ion-color-tertiary: #c41d7f;
  --ion-color-tertiary-rgb: 196, 29, 127;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #ac1a70;
  --ion-color-tertiary-tint: #ca348c;

  --ion-color-success: #2dd55b;
  --ion-color-success-rgb: 45, 213, 91;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #28bb50;
  --ion-color-success-tint: #42d96b;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #c5000f;
  --ion-color-danger-rgb: 197, 0, 15;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #ad000d;
  --ion-color-danger-tint: #cb1a27;

  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 255, 255, 255;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d8dade;
  --ion-color-light-tint: #f7f9fc;

  --ion-color-medium: #5f5f5f;
  --ion-color-medium-rgb: 95, 95, 95;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #545454;
  --ion-color-medium-tint: #6f6f6f;

  --ion-color-dark: #2f2f2f;
  --ion-color-dark-rgb: 47, 47, 47;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #292929;
  --ion-color-dark-tint: #444444;

  //Neutral color Palette from Ant Design
  --grey-1: #{$grey-1};
  --grey-2: #{$grey-2};
  --grey-3: #{$grey-3};
  --grey-4: #{$grey-4};
  --grey-5: #{$grey-5};
  --grey-6: #{$grey-6};
  --grey-7: #{$grey-7};
  --grey-8: #{$grey-8};
  --grey-9: #{$grey-9};
  --grey-10: #{$grey-10};
  --grey-11: #{$grey-11};

  --red-1: #{$red-10};
  --red-2: #{$red-9};
  --red-3: #{$red-8};
  --red-4: #{$red-7};
  --red-5: #{$red-6};
  --red-6: #{$red-5};
  --red-7: #{$red-4};
  --red-8: #{$red-3};
  --red-9: #{$red-2};
  --red-10: #{$red-1};

  --volcano-1: #{$volcano-10};
  --volcano-2: #{$volcano-9};
  --volcano-3: #{$volcano-8};
  --volcano-4: #{$volcano-7};
  --volcano-5: #{$volcano-6};
  --volcano-6: #{$volcano-5};
  --volcano-7: #{$volcano-4};
  --volcano-8: #{$volcano-3};
  --volcano-9: #{$volcano-2};
  --volcano-10: #{$volcano-1};

  --orange-1: #{$orange-10};
  --orange-2: #{$orange-9};
  --orange-3: #{$orange-8};
  --orange-4: #{$orange-7};
  --orange-5: #{$orange-6};
  --orange-6: #{$orange-5};
  --orange-7: #{$orange-4};
  --orange-8: #{$orange-3};
  --orange-9: #{$orange-2};
  --orange-10: #{$orange-1};

  --gold-1: #{$gold-10};
  --gold-2: #{$gold-9};
  --gold-3: #{$gold-8};
  --gold-4: #{$gold-7};
  --gold-5: #{$gold-6};
  --gold-6: #{$gold-5};
  --gold-7: #{$gold-4};
  --gold-8: #{$gold-3};
  --gold-9: #{$gold-2};
  --gold-10: #{$gold-1};

  --yellow-1: #{$yellow-10};
  --yellow-2: #{$yellow-9};
  --yellow-3: #{$yellow-8};
  --yellow-4: #{$yellow-7};
  --yellow-5: #{$yellow-6};
  --yellow-6: #{$yellow-5};
  --yellow-7: #{$yellow-4};
  --yellow-8: #{$yellow-3};
  --yellow-9: #{$yellow-2};
  --yellow-10: #{$yellow-1};

  --lime-1: #{$lime-10};
  --lime-2: #{$lime-9};
  --lime-3: #{$lime-8};
  --lime-4: #{$lime-7};
  --lime-5: #{$lime-6};
  --lime-6: #{$lime-5};
  --lime-7: #{$lime-4};
  --lime-8: #{$lime-3};
  --lime-9: #{$lime-2};
  --lime-10: #{$lime-1};

  --green-1: #{$green-10};
  --green-2: #{$green-9};
  --green-3: #{$green-8};
  --green-4: #{$green-7};
  --green-5: #{$green-6};
  --green-6: #{$green-5};
  --green-7: #{$green-4};
  --green-8: #{$green-3};
  --green-9: #{$green-2};
  --green-10: #{$green-1};

  --cyan-1: #{$cyan-10};
  --cyan-2: #{$cyan-9};
  --cyan-3: #{$cyan-8};
  --cyan-4: #{$cyan-7};
  --cyan-5: #{$cyan-6};
  --cyan-6: #{$cyan-5};
  --cyan-7: #{$cyan-4};
  --cyan-8: #{$cyan-3};
  --cyan-9: #{$cyan-2};
  --cyan-10: #{$cyan-1};

  --blue-1: #{$blue-10};
  --blue-2: #{$blue-9};
  --blue-3: #{$blue-8};
  --blue-4: #{$blue-7};
  --blue-5: #{$blue-6};
  --blue-6: #{$blue-5};
  --blue-7: #{$blue-4};
  --blue-8: #{$blue-3};
  --blue-9: #{$blue-2};
  --blue-10: #{$blue-1};

  --geekblue-1: #{$geekblue-10};
  --geekblue-2: #{$geekblue-9};
  --geekblue-3: #{$geekblue-8};
  --geekblue-4: #{$geekblue-7};
  --geekblue-5: #{$geekblue-6};
  --geekblue-6: #{$geekblue-5};
  --geekblue-7: #{$geekblue-4};
  --geekblue-8: #{$geekblue-3};
  --geekblue-9: #{$geekblue-2};
  --geekblue-10: #{$geekblue-1};

  --purple-1: #{$purple-10};
  --purple-2: #{$purple-9};
  --purple-3: #{$purple-8};
  --purple-4: #{$purple-7};
  --purple-5: #{$purple-6};
  --purple-6: #{$purple-5};
  --purple-7: #{$purple-4};
  --purple-8: #{$purple-3};
  --purple-9: #{$purple-2};
  --purple-10: #{$purple-1};

  --magenta-1: #{$magenta-10};
  --magenta-2: #{$magenta-9};
  --magenta-3: #{$magenta-8};
  --magenta-4: #{$magenta-7};
  --magenta-5: #{$magenta-6};
  --magenta-6: #{$magenta-5};
  --magenta-7: #{$magenta-4};
  --magenta-8: #{$magenta-3};
  --magenta-9: #{$magenta-2};
  --magenta-10: #{$magenta-1};

  //Application Colors
  //Item
  --ion-item-background: transparent;
  --ion-item-color: #{$grey-10};
  //Card
  --ion-card-background: #{$grey-1};
  //Background
  --ion-background-color: #{$grey-2};
  --ion-background-color-rgb: 250, 250, 250;
  //Tab
  --ion-tab-bar-background: none;
  --ion-tab-bar-background-focused: none;
  --ion-tab-bar-border-color: #d9d9d9;
  --ion-tab-bar-color: #8c8c8c;
  --ion-tab-bar-color-selected: var(--ion-color-primary-shade);
  //Toolbar
  --ion-toolbar-border-color: #d9d9d9;
  --ion-toolbar-color: #{$grey-10};
  --ion-toolbar-segment-color: #8c8c8c;
  --ion-toolbar-segment-color-checked: #{$grey-10};
  --ion-toolbar-segment-background: none;
  --ion-toolbar-segment-background-checked: none;
  --ion-toolbar-background: #{$grey-2};
  //Text
  --ion-text-color: #000000;
  --ion-text-color-rgb: 0, 0, 0;
}

/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import './theme/extensions/bootstrap/index.scss';

@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/root';
@import 'node_modules/bootstrap/scss/maps';
@import 'node_modules/bootstrap/scss/utilities';
@import 'node_modules/bootstrap/scss/utilities/api';

@import './theme/global/normalize';

@import './theme/extensions/ionic/index.scss';
@import './theme/utilities/index.scss';
@import './theme/variables/index.scss';

@font-face {
  font-family: 'Roboto Flex';
  src: url('./assets/fonts/RobotoFlex.ttf');
}

* {
  &:focus {
    outline: 0;
  }
}

body {
  font-family: 'Roboto Flex', sans-serif;
}

ion-button {
  --box-shadow: none;
}

ion-content {
  --color: var(--grey-10);
}

hr {
  border-top: 1px solid var(--grey-8);
  width: 100%;
}

.header-md::after {
  content: '';
  position: absolute;
  height: 6px;
  top: 0;
  width: 100%;
  background: none;
  opacity: 0.5;
  z-index: 100;
}

ion-accordion {
  background: transparent;
}

.NgxEditor__Wrapper {
  max-width: 100%;
}

.NgxEditor__Wrapper.readonly-editor {
  border: none;

  .NgxEditor__Content {
    padding: 0.5rem 0;
    pointer-events: initial !important;
  }
}

.dot {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  flex-shrink: 0;
  background-color: currentColor;

  &-small {
    width: 12px;
    height: 12px;
  }
}

ion-item-divider {
  background: none;
}

@media (prefers-color-scheme: light) {
  ion-button:not([color]):not([fill='clear']):not([fill='outline']) {
    --background: var(--wilson);
  }
  body {
    --color: var(--grey-10);
  }
}

@media (prefers-color-scheme: dark) {
  ion-content {
    --ion-background-color: var(--grey-2);
    --color: var(--grey-10);
  }
  body {
    --color: var(--grey-10);
  }
  body:not(.dark) {
    ion-button:not([color]):not([fill='clear']):not([fill='outline']) {
      --background: var(--wilson);
    }
  }
}

/* Fallback for older browsers or manual mode */
body.dark ion-content {
  --ion-background-color: var(--grey-2);
  --color: var(--grey-10);
}

ion-toast.toast-offset-bottom {
  translate: 0 -5rem;
}

ion-toast.custom-toast {
  --border-color: var(--geekblue-6);
  --border-radius: 6px;
  --border-style: solid;
  --color: var(--geekblue-6);
  --border-width: 1px;
}

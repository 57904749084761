ion-segment {
  &-button {
    font-size: 18px;
    text-transform: capitalize;
    flex: 1;
    --ripple-color: transparent;

    &.ios {
      @extend .text-subtitle;
    }
  }
}
